import React from "react"
export default function Margin(props) {
	return (
		<div
			style={{
				marginTop: props.margin,
				width: "100%",
			}}
		/>
	)
}
