import React from "react"
import { Typography } from "antd"
import { useNavigate } from "react-router-dom"
const { Title } = Typography
export default function Logo(props) {
	const navigate = useNavigate()
	return (
		<>
			<Title
				level={1}
				style={{
					fontFamily: "Gugi",
					color: "#0f0f0f",
					cursor: "pointer",
				}}
				onClick={(e) => {
					e.preventDefault()
					if (props.button) {
						navigate("/")
					}
				}}>
				프롬트<span style={{ color: "#0040ff" }}>버디</span>
			</Title>
			{props.withSlogan && <Title level={5}>생성 AI 프롬트 헬퍼</Title>}
		</>
	)
}
