import React from "react"
import { Navigate, Outlet } from "react-router-dom"
import { getAuth } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"
import { fbapp } from "../firebase"
import { Col, Spin } from "antd"

const PrivateRoute = ({ children, ...rest }) => {
	const auth = getAuth(fbapp)
	const [user, loading] = useAuthState(auth)

	return user ? (
		<Outlet />
	) : loading ? (
		<Col
			span={12}
			offset={6}
			style={{ textAlign: "center", marginTop: "10em" }}>
			<Spin />
		</Col>
	) : (
		<Navigate to={"/login"} />
	)
}

export default PrivateRoute
