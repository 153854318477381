import "./App.css"
import { ConfigProvider } from "antd"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"
import Landing from "./pages/Landing"
import Signup from "./pages/Signup"
import Home from "./pages/Home"
import PrivateRoute from "./components/PrivateRoute"
import ConfirmAuth from "./pages/ConfirmAuth"
import Example from "./components/Example"
import { useEffect } from "react"
import ReactGA from "react-ga4"

// blue #3F00FF
// black 0f0f0f
// require("dotenv").config()
function App() {
	useEffect(() => {
		ReactGA.initialize([
			{
				trackingId: "G-J6CKN7KBKH",
			},
		])
	})

	return (
		<ConfigProvider
			theme={{
				token: {
					colorPrimary: "#3F00FF",
					fontFamily: "Noto Sans KR",
					colorSuccess: "#0044ff",
					colorError: "#ff0044",
				},
			}}>
			<Router>
				<Routes>
					<Route element={<PrivateRoute />}>
						<Route path={"/"} element={<Home />} />
					</Route>
					<Route path="/login" element={<Landing />} />
					<Route path="/signup" element={<Signup />} />
					<Route path="/confirm" element={<ConfirmAuth />} />
					<Route path="/example" element={<Example />} />
					{/* <Route>
						<NotFound />
					</Route> */}
				</Routes>
			</Router>
		</ConfigProvider>
	)
}

export default App
