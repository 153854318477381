import { Col, Row } from "antd"
import React, { useEffect, useState } from "react"
import { styled } from "styled-components"
import { MailOutlined, ArrowRightOutlined } from "@ant-design/icons"
import { Button, Form, Input } from "antd"
import { useSendSignInLinkToEmail } from "react-firebase-hooks/auth"
import { collection, query, where, getDocs } from "firebase/firestore"
import { auth, db } from "../firebase"
import EmailTag from "../components/EmailTag"
import Margin from "../components/Margin"
import { fbSigninOptions } from "../constants"
import { useSearchParams } from "react-router-dom"
import EmailValidationCheck from "../components/EmailValidationCheck"
import Logo from "../components/Logo"
import usePathAnalytics from "../logic/usePathAnalytics"

const BorderedDiv = styled.div`
	background: transparent;
	padding-top: 6em;
`
const SInput = styled(Input)`
	width: 100%;
	height: 3.5em;
`
const SButton = styled(Button)`
	width: 100%;
	height: 3.5em;
`

export default function Landing() {
	usePathAnalytics()
	const [stat, setstat] = useState()
	const [sendSignInLinkToEmail, sending, error] =
		useSendSignInLinkToEmail(auth)
	const [searchParams] = useSearchParams()
	const [domain, setdomain] = useState("")
	useEffect(() => {
		if (error) {
			setstat("fail")
		}
		if (sending) {
			setstat("processing")
		}
	}, [sending, error])

	const onFinish = async (values) => {
		const isSignedUp = await validateUserSignedUp(values.email)
		if (isSignedUp) {
			const succ = await sendSignInLinkToEmail(values.email, {
				...fbSigninOptions,
				url:
					fbSigninOptions.url +
					`?email=${encodeURIComponent(values.email)}`,
			})
			if (succ) {
				setdomain(values.email.split("@")[1])
				setstat("success")
			} else {
				setstat("fail")
			}
		} else {
			setstat("failNoAcc")
		}
	}

	const validateUserSignedUp = async (email) => {
		const usersRef = collection(db, "users")
		const q = query(usersRef, where("email", "==", email))
		const dcs = await getDocs(q)

		if (dcs.empty) {
			return false
		} else {
			return true
		}
	}

	const parseEmailDomainToLink = () => {
		var emailInboxURLs = {
			"gmail.com": "https://mail.google.com/mail/u/0/#inbox",
			"yahoo.com": "https://mail.yahoo.com/d/folders/1",
			"naver.com": "https://mail.naver.com/",
			"kakao.com": "https://mail.kakao.com/.",
		}

		if (domain in emailInboxURLs) {
			// Generate the inbox URL
			var inboxURL = emailInboxURLs[domain]
			return inboxURL
		} else {
			// Handle the case when the domain is not found in the mapping
			return "https://www." + domain
		}
	}

	return (
		<>
			<Col
				span={22}
				offset={1}
				style={{ textAlign: "center", marginTop: "10%" }}>
				<Logo withSlogan />
				<a rel="noreferrer" href={"/example"} target="_blank">
					예시보기
				</a>
				<Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }}>
					<BorderedDiv>
						<Col span={18} offset={3}>
							<Form
								name="normal_login"
								className="login-form"
								initialValues={{
									remember: true,
								}}
								onFinish={onFinish}>
								{searchParams.get("r") === "v" && !stat && (
									<>
										<EmailValidationCheck
											stat={"success"}
										/>
										<Margin margin={"1em"} />
									</>
								)}
								<Form.Item
									name="email"
									rules={[
										{
											required: true,
											message: "이메일을 입력해주세요!",
										},
									]}>
									<SInput
										prefix={
											<MailOutlined className="site-form-item-icon" />
										}
										placeholder="이메일"
									/>
								</Form.Item>
								<Margin margin={"3em"} />
								<EmailTag stat={stat} />
								<Margin margin={"1em"} />
								{stat === "success" && (
									<a
										rel="noreferrer"
										href={parseEmailDomainToLink()}
										target="_blank">
										<span style={{ marginRight: "0.5em" }}>
											<ArrowRightOutlined />
										</span>
										이메일 확인하러 가기
									</a>
								)}
								<Margin margin={"1em"} />
								<Form.Item>
									<SButton
										style={{
											width: "100%",
										}}
										type="primary"
										htmlType="submit"
										className="login-form-button">
										로그인
									</SButton>
									<Row align={"middle"} justify={"center"}>
										<p style={{ marginRight: "1em" }}>
											계정이 없으신가요?
										</p>
										<a
											className="login-form-forgot"
											href="/signup">
											계정 생성
										</a>
									</Row>
								</Form.Item>
							</Form>
						</Col>
					</BorderedDiv>
				</Col>
			</Col>
		</>
	)
}
