import React, { useEffect } from "react"
import { useSignInWithEmailLink } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { isSignInWithEmailLink } from "firebase/auth"
import { auth } from "../firebase"
import ReactGA from "react-ga4"

export default function ConfirmAuth() {
	const [signInWithEmailLink] = useSignInWithEmailLink(auth)
	const navigate = useNavigate()
	useEffect(() => {
		if (isSignInWithEmailLink(auth, window.location.href)) {
			// after that we will complete the login process
			var urlParams = []
			window.location.search
				.replace("?", "")
				.split("&")
				.forEach(function (e, i) {
					var p = e.split("=")
					urlParams[p[0]] = p[1]
				})
			if (urlParams["apiKey"] === process.env.REACT_APP_FB_API_KEY)
				signInWithEmailLink(
					// auth,
					decodeURIComponent(urlParams["email"]),
					window.location.href
				)
					.then((result) => {
						ReactGA.event({
							category: "auth",
							action: "login",
						})
						// we can get the user from result.user but no need in this case
						navigate("/")
					})
					.catch((err) => {
						ReactGA.event({
							category: "auth",
							action: "login error",
						})
						navigate("/login")
					})
		}
	})

	return <></>
}
