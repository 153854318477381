import React, { useEffect, useState } from "react"

export default function AdsContainer() {
	const [windowSize, setWindowSize] = useState(window.innerWidth)

	useEffect(() => {
		const handleWindowResize = () => {
			setWindowSize(window.innerWidth)
		}

		window.addEventListener("resize", handleWindowResize)

		return () => {
			window.removeEventListener("resize", handleWindowResize)
		}
	}, [])

	return (
		<iframe
			title={"coupangAd"}
			src="https://ads-partners.coupang.com/widgets.html?id=672739&template=carousel&trackingCode=AF2958310&subId=&width=800&height=140&tsource="
			width={windowSize * 0.5}
			height="140"
			frameborder="0"
			scrolling="no"
			referrerpolicy="unsafe-url"></iframe>
	)
}
