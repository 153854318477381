import { Col, Collapse, Typography, theme } from "antd"
import React from "react"
import Logo from "./Logo"
import Margin from "./Margin"
import { CaretRightOutlined } from "@ant-design/icons"

const { Text, Title } = Typography

export default function Example() {
	const { token } = theme.useToken()

	const panelStyle = {
		marginBottom: 24,
		background: token.colorFillAlter,
		borderRadius: token.borderRadiusLG,
		border: "none",
	}

	const normalEx = `불고기는 한국 요리 중에서도 매우 인기 있는 요리로, 소고기를 간장과 설탕 등의 양념으로 재워 구워먹는 스타일입니다. 아래는 전통적인 불고기 레시피입니다.

재료:

소고기 (차돌박이나 목살이 좋습니다) - 500g
양파 - 1개 (중간 크기)
대파 - 2대
마늘 - 4~5쪽
간장 - 4큰술
설탕 - 2큰술
참기름 - 1큰술
깨소금 - 1작은술
후춧가루 - 약간
참기름 - 약간 (불고기를 구울 때 사용)

조리 절차:

소고기는 얇게 썰어 준비합니다. 소고기를 고기맵시나 고기용 소금에 약간 절인 후 물에 한번 헹궈서 진하게 씻어낸 후 천장지에 놓고 물기를 제거합니다.
양파는 얇게 채 썰어 준비합니다. 대파와 마늘도 곱게 다져 둡니다.
소고기를 그릇에 넣고, 간장, 설탕, 참기름, 깨소금, 후춧가루를 넣은 양념장을 만들어 소고기에 골고루 섞어줍니다.
`
	const pbEx = `
물론입니다! 맛있고 정통한 한국 불고기 요리를 준비하는 과정을 안내해 드리겠습니다. 불고기는 얇게 썬 소고기를 짭짤하고 약간 달콤한 양념에 재워 구워내어 부드럽고 풍미 있는 고기를 만드는 대표적인 한국의 구움 요리입니다. 시작해봅시다!

불고기를 준비하는 데 필요한 주요 재료는 다음과 같습니다:

양념 재료:

얇게 썬 소고기 500그램 (약 1.1 파운드) (리브아이 또는 등심 부위가 좋습니다)
간장 1/2컵
설탕 3큰 숟가락
참기름 2큰 숟가락
쌀 주머니 (단 단백질)
다진 마늘 4쪽
다진 양파 1개
다진 생강 1큰 숟가락
볶은 참깨 2큰 숟가락
채소유 2큰 숟가락 (구워내기용)
음식 제공을 위해:

잘게 썬 파 (선택 사항)
볶은 참깨 (선택 사항)
즉석 밥
상추 잎 (싸서 먹는 데 사용)
이제 준비 및 양념 과정으로 넘어갑시다:

1단계: 양념 준비
그릇에 간장, 설탕, 참기름, 쌀 주머니, 다진 마늘, 다진 양파, 다진 생강, 볶은 참깨를 넣고 잘 섞습니다. 설탕이 녹고 재료가 완전히 섞일 때까지 충분히 섞어줍니다.

2단계: 소고기 양념
얇게 썬 소고기를 얕은 접시나 지퍼백에 올려줍니다. 양념 재료를 소고기 위에 부어 고루 덮어줍니다. 손이나 집게를 사용하여 양념을 고기에 마사지하면서 각 조각이 잘 묻도록 합니다. 접시를 덮거나 지퍼백을 밀폐한 다음, 최소 1시간 이상 냉장고에서 재워줍니다. 가능하면 최상은 밤새 재워 양념이 잘 베어들도록 합니다.

3단계: 그릴 또는 팬 예열
그릴을 사용하는 경우, 중간에서 높은 온도로 예열합니다. 불고기를 팬으로 구워내는 경우, 대형 스키렛이나 그릴 팬을 중간에서 높은 온도로 예열합니다. 식물성 오일을 넣고 뜨거워지도록 합니다.

4단계: 소고기 구워내기
그릴 사용 시: 양념에 재워진 소고기 조각을 예열된 그릴에 올려줍니다. 약 2~3분씩 한 면당 구워내어 소고기가 완전히 익고 약간 색이 가도록 합니다. 과도한 조리로 고기가 불타지 않도록 주의합니다.

팬으로 구워내는 경우: 양념에 재워진 소고기 조각을 뜨거운 스키렛이나 그릴 팬에 넣어줍니다. 약 2~3분씩 한 면당 구워내어 소고기가 완전히 익고 약간 색이 가도록 합니다. 팬이 너무 복잡하게 되지 않도록 고기를 여러 번 구워야 할 수도 있습니다.

5단계: 휴식 및 얇게 써먹기
소고기가 조리된 후, 열에서 내려놓고 잠시 쉬어줍니다. 이렇게 하면 육즙이 고루 퍼지고 고기가 더 부드러워집니다. 휴식 후에는 고기를 곡선 방향으로 얇게 썬 후 제공합니다. 이렇게 하면 고기가 부드럽고 씹기 쉬운 질감을 가지게 됩니다.

6단계: 제공 및 장식
얇게 썬 불고기를 서빙용 접시에 옮겨줍니다. 원한다면 얇게 썬 파와 볶은 참깨를 올려 장식합니다. 즉석 밥과 싸서 먹을 수 있는 상추 잎과 함께 제공합니다. 또한 김치와 같은 전통적인 한국의 반찬과 함께 제공할 수도 있습니다.

이제 맛있는 집에서 만든 불고기를 즐길 준비가 되었습니다! 짭짤하고 달콤하며 고소한 맛과 부드러운 소고기의 조합은 이 요리를 매력적으로 만듭니다. 개인적인 취향에 맞게 양념 재료를 조절하셔도 좋습니다. 더욱 달콤하고 부드러움을 더하려면 양념에 배나 사과 퓨레를 약간 추가할 수도 있습니다.

이 안내가 도움이 되었기를 바라며, 멋진 불고기 식사를 성공적으로 준비하시길 바랍니다. 맛있게 즐기세요!
`
	const getItems = (panelStyle) => [
		{
			key: "1",
			label: "CHATGPT에 <불고기 만드는 법> 을 물어본다면?",
			children: (
				<Text style={{ whiteSpace: "pre-wrap" }}>{normalEx}</Text>
			),
			style: panelStyle,
		},
		{
			key: "2",
			label: "CHATGPT에 프롬트버디를 통해 생성된 <불고기 만드는 법> 을 물어본다면?",
			children: <Text style={{ whiteSpace: "pre-wrap" }}>{pbEx}</Text>,
			style: panelStyle,
		},
		{
			key: "3",
			label: "프롬트버디의 프롬트는 무엇이 다른가요?",
			children: (
				<Text>
					생성형AI는 질문의 퀄리티에 따라 답변에 확연한 차이를 보이며
					프롬트버디는 AI를 통해 상세한 프롬트를 작성하여 더욱 정교한
					결과를 얻어낼 수 있게 도와줍니다.
					<br /> 프롬트버디에 원하는 질문을 물어보고 답변을 ChatGPT에
					질문해 보세요!
				</Text>
			),
			style: panelStyle,
		},
	]

	return (
		<>
			<Col
				span={22}
				offset={1}
				style={{ textAlign: "center", marginTop: "10%" }}>
				<Logo button />
				<Margin margin={"1em"} />
				<Title level={5}>
					프롬트버디를 이용하면 훨씬 더 정교한 결과를 받을 수
					있습니다.
				</Title>
				<Margin margin={"4em"} />
				<Col
					xs={{ span: 22, offset: 1 }}
					lg={{ span: 12, offset: 6 }}
					style={{ textAlign: "left" }}>
					<Collapse
						bordered={false}
						defaultActiveKey={["2"]}
						expandIcon={({ isActive }) => (
							<CaretRightOutlined rotate={isActive ? 90 : 0} />
						)}
						style={{ background: token.colorBgContainer }}
						items={getItems(panelStyle)}
					/>
				</Col>
			</Col>
		</>
	)
}
