import { Col, Button, Form, Input } from "antd"
import React, { useEffect, useState } from "react"
import { Typography } from "antd"
import { styled } from "styled-components"
import { MailOutlined, UserOutlined } from "@ant-design/icons"
import { db } from "../firebase"
import Margin from "../components/Margin"
import { collection, query, where, getDocs, addDoc } from "firebase/firestore"
import EmailValidationCheck from "../components/EmailValidationCheck"
import { useNavigate } from "react-router-dom"
import usePathAnalytics from "../logic/usePathAnalytics"

const { Title } = Typography

const BorderedDiv = styled.div`
	background: transparent;
	padding-top: 6em;
`
const SInput = styled(Input)`
	width: 100%;
	height: 3.5em;
`
const SButton = styled(Button)`
	width: 100%;
	height: 3.5em;
`

export default function Signup() {
	usePathAnalytics()
	const [stat, setstat] = useState()
	const navigate = useNavigate()
	useEffect(() => {
		if (stat === "success") {
			navigate("/login?r=v")
		}
	}, [stat, navigate])

	const onFinish = async (values) => {
		const usersRef = collection(db, "users")
		const q = query(usersRef, where("email", "==", values.email))
		const dcs = await getDocs(q)

		if (dcs.empty) {
			try {
				const docRef = await addDoc(usersRef, {
					email: values.email,
					name: values.username,
				})
				docRef ? setstat("success") : setstat("failCreate")
			} catch (err) {
				console.log(err)
				setstat("failCreate")
			}
		} else {
			setstat("fail")
		}
	}

	return (
		<>
			<Col
				span={22}
				offset={1}
				style={{ textAlign: "center", marginTop: "15%" }}>
				<Title
					level={1}
					style={{ fontFamily: "Gugi", color: "#0f0f0f" }}>
					프롬트<span style={{ color: "#0040ff" }}>버디</span>
				</Title>
				<Title level={5}>계정 생성</Title>
				<Col xs={{ span: 22, offset: 1 }} lg={{ span: 8, offset: 8 }}>
					<BorderedDiv>
						<Col span={18} offset={3}>
							<Form
								name="normal_login"
								className="login-form"
								initialValues={{
									remember: true,
								}}
								onFinish={onFinish}>
								<Form.Item
									name="username"
									rules={[
										{
											required: true,
											message: "이름을 입력해주세요!",
										},
									]}>
									<SInput
										prefix={
											<UserOutlined className="site-form-item-icon" />
										}
										placeholder="이름"
									/>
								</Form.Item>
								<Form.Item
									name="email"
									rules={[
										{
											required: true,
											message: "이메일을 입력해주세요!",
										},
									]}>
									<SInput
										prefix={
											<MailOutlined className="site-form-item-icon" />
										}
										placeholder="이메일"
									/>
								</Form.Item>
								<Margin margin={"3em"} />
								<EmailValidationCheck stat={stat} />
								<Margin margin={"1em"} />
								<Form.Item>
									<SButton
										style={{
											width: "100%",
										}}
										type="primary"
										htmlType="submit"
										className="login-form-button">
										계정 생성
									</SButton>
								</Form.Item>
							</Form>
						</Col>
					</BorderedDiv>
				</Col>
			</Col>
		</>
	)
}
