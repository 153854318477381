import React from "react"
import {
	CheckCircleOutlined,
	CloseCircleOutlined,
	SyncOutlined,
} from "@ant-design/icons"
import { Tag } from "antd"

const statData = {
	success: {
		icon: <CheckCircleOutlined />,
		text: "계정이 생성되었습니다!",
		color: "success",
	},
	processing: {
		icon: <SyncOutlined spin />,
		text: "계정을 생성중입니다.",
		color: "default",
	},
	fail: {
		icon: <CloseCircleOutlined />,
		text: "이미 등록된 계정입니다.",
		color: "error",
	},
	failCreate: {
		icon: <CloseCircleOutlined />,
		text: "계정 생성에 실패했습니다. 다시 시도해 주세요.",
		color: "error",
	},
}
export default function EmailValidationCheck(props) {
	if (props.stat) {
		return (
			<Tag
				icon={statData[props.stat]["icon"]}
				color={statData[props.stat]["color"]}>
				{statData[props.stat]["text"]}
			</Tag>
		)
	}
}
