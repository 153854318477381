// import React from "react"

// export default function Home() {

// 	return <div>{JSON.stringify(user)}</div>
// }
// import { AppstoreOutlined } from "@ant-design/icons"
import { Col, Typography, Input, Spin } from "antd"
import React, { useEffect, useState } from "react"
import { db, fbapp } from "../firebase"
import { useAuthState } from "react-firebase-hooks/auth"
import { getAuth } from "firebase/auth"
import Logo from "../components/Logo"
import Margin from "../components/Margin"
import { collection, query, where, getDocs, addDoc } from "firebase/firestore"
import { styled } from "styled-components"
import { detectLang, reqGpt, translateToEn } from "../logic/Promptify"
import AdsContainer from "../components/AdsContainer"
import ReactGA from "react-ga4"
import usePathAnalytics from "../logic/usePathAnalytics"

const { Title, Text } = Typography
const { Search } = Input
const SSearch = styled(Search)`
	height: 3.5em;
`

const Home = () => {
	usePathAnalytics()
	const auth = getAuth(fbapp)
	const [user, loading] = useAuthState(auth)
	const [userName, setuserName] = useState()

	const [apiResponse, setapiResponse] = useState("")
	const [loadingRes, setloadingRes] = useState(false)

	useEffect(() => {
		const getUserName = async () => {
			const usersRef = collection(db, "users")
			const q = query(usersRef, where("email", "==", user.email))
			const dcs = await getDocs(q)
			if (!dcs.empty) {
				setuserName(dcs.docs[0].data().name)
			}
			return dcs
		}

		if (!loading && user) {
			getUserName()
		}
	}, [loading, user])

	const onFinish = async (value) => {
		setloadingRes(true)
		const lang = detectLang(value)
		ReactGA.event({
			category: "action",
			action: "Promptified",
			label: `request language: ${lang}`,
		})

		if (lang === "eng") {
			const promptified = await reqGpt(value)
			setapiResponse(promptified)
		} else {
			value = await translateToEn(value)
		}

		const promptified = await reqGpt(value, lang)
		await createPromptDoc(promptified, value, lang)
		setapiResponse(promptified)
		setloadingRes(false)
	}

	const createPromptDoc = async (prompt, original, language) => {
		const promptRef = collection(db, "prompts")
		try {
			const create = await addDoc(promptRef, {
				email: user.email,
				created_at: new Date(),
				query: original,
				prompt: prompt,
				lang: language,
			})
			console.log(create)
		} catch (err) {
			console.log(err)
		}
	}

	return (
		<>
			<Col
				span={22}
				offset={1}
				style={{ textAlign: "center", marginTop: "5%" }}>
				<Logo />
				<Margin margin={"1em"} />
				<Title level={5}>
					어떤 프롬트가 필요하신가요? {`${userName}님`}
				</Title>
				<Text type="secondary">
					현재{" "}
					<a
						rel="noreferrer"
						href={"https://chat.openai.com"}
						target={"_blank"}>
						ChatGPT
					</a>{" "}
					프롬트만 지원합니다.{" "}
				</Text>
				<Margin margin={"4em"} />
				<Col xs={{ span: 22, offset: 1 }} lg={{ span: 12, offset: 6 }}>
					<SSearch
						size={"large"}
						placeholder="프롬트는 구체적일수록 좋습니다. (한글 & 영어 모두 지원)"
						enterButton={"생성"}
						onSearch={onFinish}
						loading={loadingRes}
					/>
				</Col>

				<Margin margin={"4em"} />
				{apiResponse &&
					(loadingRes ? (
						<Col span={12} offset={6}>
							<Spin />
						</Col>
					) : (
						<Col
							span={12}
							offset={6}
							xs={{ span: 22, offset: 1 }}
							lg={{ span: 12, offset: 6 }}>
							<Text
								type="primary"
								copyable={{
									tooltips: false,
									onCopy: () => {
										ReactGA.event({
											category: "action",
											action: "Promptified",
											label: `copied`,
										})
									},
								}}
								style={{ width: "100%" }}>
								{apiResponse}
							</Text>
							<Margin margin={"3em"} />
							<Text type="secondary">
								위 글을 복사해{" "}
								<a
									rel="noreferrer"
									href={"https://chat.openai.com"}
									target={"_blank"}>
									ChatGPT
								</a>
								에 붙여넣어보세요!
							</Text>
						</Col>
					))}
				<Margin margin={"10em"} />
				<AdsContainer />
			</Col>
			<Margin margin={"10em"} />
		</>
	)
}

export default Home
