const { Configuration, OpenAIApi } = require("openai")
const configuration = new Configuration({
	apiKey: process.env.REACT_APP_OPENAI_API_KEY,
})
const { franc } = require("franc-min")
const openai = new OpenAIApi(configuration)

export const reqGpt = async (message, language) => {
	const newTxt = `Act as a prompt generator for ChatGPT. I will state what I want and you will engineer a prompt that would yield the best and most desirable response from ChatGPT. Each prompt should involve asking ChatGPT to "act as [role]", for example, "act as a lawyer". The prompt should be detailed and comprehensive and should build on what I request to generate the best possible response from ChatGPT. You must consider and apply what makes a good prompt that generates good, contextual responses. Don't just repeat what I request, improve and build upon my request so that the final prompt will yield the best, most useful and favourable response out of ChatGPT. Place any variables in square brackets
  Here is the prompt I want: ${message}. Only return the prompt as the result.`

	try {
		const chatCompletion = await openai.createChatCompletion({
			model: "gpt-3.5-turbo",
			messages: [{ role: "user", content: newTxt }],
		})
		return (
			chatCompletion.data.choices[0].message.content +
			" Give me the answer in " +
			language
		)
	} catch (e) {
		console.log(e)
		return new Error("오류가 발생했습니다.")
	}
}

export const translateToEn = async (message) => {
	const newTxt = `${message} / 앞에 문장이 영어로 뭐야?`
	try {
		const chatCompletion = await openai.createChatCompletion({
			model: "gpt-3.5-turbo",
			messages: [{ role: "user", content: newTxt }],
		})
		return chatCompletion.data.choices[0].message.content
	} catch (e) {
		console.log(e)
		return new Error("오류가 발생했습니다.")
	}
}

export const detectLang = (txt) => {
	const detectedLanguage = franc(txt, { minLength: 1 })

	return detectedLanguage
}
