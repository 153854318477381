import { useEffect } from "react"
import { useLocation } from "react-router-dom"
import ReactGA from "react-ga4"
const usePathAnalytics = () => {
	const location = useLocation()
	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page_path: location.pathname,
		})
	}, [location])
}

export default usePathAnalytics
